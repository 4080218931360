import React, {useState, useContext, useEffect, useRef} from 'react';
import {Context} from '../../AppContext';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {message, Result} from 'antd';
import {formatValidator} from '../../Utils';
import {ErrForgetPwd} from '../../errors';
import {Label, Row, Input, Button, Text} from '../../Widgets';

const {isNotEmpty, isEmail} = formatValidator;

function errorHandler(ex) {
  console.warn(ex);
  if (ex instanceof ErrForgetPwd) {
    message.warning(ex.message);
  } else if (
    ex.error === 'validation_error' &&
    ex.detail &&
    ex.detail.indexOf('does not exist') > -1
  ) {
    message.warning(
      '無此用戶，請確認是否是以此信箱進行一般註冊，社群登入無法使用忘記密碼功能。',
    );
  } else if (ex.status === '404') {
    message.warning(
      '無此用戶，請確認是否是以此信箱進行一般註冊，社群登入無法使用忘記密碼功能。',
    );
  } else {
    message.error('發生錯誤');
  }
}

const UISTATE = {
  init: 0,
  submitting: 1,
  submitted: 2,
};

export default function AuthRedirect() {
  const app = useContext(Context);
  const [uiState, setUiState] = useState(UISTATE.init);
  const [email, setEmail] = useState('');

  const submit = async () => {
    app.actions.setLoading(true);
    setUiState(UISTATE.submitting);

    try {
      if (!isNotEmpty(email)) {
        throw new ErrForgetPwd('信箱為必填');
      }
      if (!isEmail(email)) {
        throw new ErrForgetPwd('信箱格式錯誤');
      }

      await app.actions.forgetPassword({email});
      message.success('已寄送重設密碼信件');
      setUiState(UISTATE.submitted);
    } catch (ex) {
      errorHandler(ex);
    }
    app.actions.setLoading(false);
  };

  return (
    <ContentWrapper>
      <div style={{maxWidth: 600, margin: '0 auto'}}>
        {(uiState === UISTATE.submitted && (
          <Result
            title="成功!"
            subTitle={
              <Text style={{whiteSpace: 'pre'}}>
                {`請至您的註冊信箱收取重設密碼郵件: \n${email}\n信件連結時效 2 分鐘，逾時請重新操作。`}
              </Text>
            }
            status="success"
          />
        )) || (
          <>
            <h2>忘記密碼</h2>

            <div>
              <Label>您的註冊Email</Label>
              <Input
                id="fgpass-email-input"
                value={email}
                onChange={(e) => {
                  let value = e.target.value;
                  setEmail(value);
                }}
              />
            </div>

            <Row margin="20px 0" style={{justifyContent: 'flex-end'}}>
              <Button onClick={submit}>送出</Button>
            </Row>
          </>
        )}
      </div>
    </ContentWrapper>
  );
}
